<template>
    <BaseLayout>
        <template v-slot:page-content>
            <div class="container-fluid">
                    <BreadCrumb :current-page="$t('nav.all-brands')"/>
            </div>
            <div class="all favourite_products">
                <section class="container-fluid px-lg-4 custom-wrapper">
                    <div class="mr-custom ml-custom">

                        <div class="row">
                            <div v-for="brand in brands" :key="brand.id"
                                 class="col-lg-3 col-md-4 col-sm-6 col-12" data-aos="fade-up" data-aos-duration="800">
                                <BrandCard :brand="brand" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </template>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/containers/BaseLayout";
import BrandCard from "@/components/BrandCard";
import BreadCrumb from "@/components/BreadCrumb";

export default {
    name: 'Favourite',
    components: {BreadCrumb, BrandCard, BaseLayout},
    data() {
        return {
            brands:[]
        }
    },
    mounted() {
        this.brands = this.$store.getters.brands;
    }
}
</script>

<style>


</style>