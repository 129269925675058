<template>
    <div class="card w-100 custom-card brand-card">
        <router-link :to="{name: 'Brand' , params: {id: brand.id}}">
            <img class="card-img-top product-image" :src="brand.image" :alt="brand.name">
            <div class="card-body">
                <h3 class="text-center text-dark text-decoration-none">{{brand.name}}</h3>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'BrandCard',
    props:['brand']
}
</script>


<style>
.product-image {
    border-bottom: 1px solid #dedbdb;
    height: 220px;
    object-fit: cover;
    object-position: center;
}
.custom-card a {
    border: 1px solid transparent;
    transition: all 200ms ease-in-out;
}
.custom-card a:hover  {
    text-decoration: none;
    box-shadow: 0 5px 6px rgba(0,0,0,.06);
    border-color: #0E5AA6;
}

.custom-card.brand-card {
    margin-bottom: 2rem;
}
</style>