<template>
    <BaseLayout>
        <template v-slot:page-content>
            <div class="container-fluid">
                <BreadCrumb :current-page="$t('nav.all-brands')"/>
            </div>
            <div class="all favourite_products">
                <section class="container-fluid px-lg-4 custom-wrapper">
                    <div class="mr-custom ml-custom">

                        <div class="row">
                            <div class="filter_buttons flex-wrap col-12">
                                <button class="btn btn-outline-primary" v-for="category in brandCategories.category" :key="category.id"
                                        :class="{'active' : category_id === category.id}" @click="handleQualityFilter(category.id)">{{ $i18n.locale === 'ar' ? category.name_ar : category.name }}</button>

<!--                                <button class="btn btn-outline-primary" :class="{'active' : quality === 1}" @click="handleQualityFilter(1)">High</button>-->
<!--                                <button class="btn btn-outline-primary mx-1" :class="{'active' : quality === 2}" @click="handleQualityFilter(2)">Mid</button>-->
<!--                                <button class="btn btn-outline-primary mx-0" :class="{'active' : quality === 3}" @click="handleQualityFilter(3)">Low</button>-->
                            </div>

                            <Loader v-if="loading"/>

                            <div v-else class="w-100">
                                <h4 class="heading-custom px-3" v-if="Products.length">{{$t('products')}}</h4>
                               <div class="row" v-if="Products.length">
                                   <div v-for="product in Products" :key="product.id"
                                        class="col-lg-2 col-md-4 col-sm-6 col-12">
                                       <ProductCard :product="product"/>
                                   </div>
                               </div>
                                <div class="text-center py-5 border-top" v-else>
                                    {{$t('no_products')}}
                                </div>


                                <h4 class="heading-custom px-3" v-if="more_sales.length">{{$t('home.most-selling')}}</h4>

                                <div class="row">
                                    <div v-for="product in more_sales" :key="product.id"
                                         class="col-lg-2 col-md-4 col-sm-6 col-12">
                                        <ProductCard :product="product"/>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-3">
                                <pagination v-model="page"  :records="total" :per-page="10"
                                            @paginate="getProducts"/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </template>
    </BaseLayout>
</template>

<script setup>
import BaseLayout from "@/containers/BaseLayout";
import ProductCard from "@/components/ProductCard";
import BreadCrumb from "@/components/BreadCrumb";
import {SearchBrand} from "@/services/products";
import Pagination from 'v-pagination-3';
import Loader from "../components/Loader";
import {computed, onMounted, ref} from "vue";

import {useRoute} from  'vue-router'
import {useStore} from "vuex";

const store = useStore();
const route = useRoute()
const Products = ref([]);
const more_sales = ref([]);
const brand_id = ref(route.params.id);
const loading = ref(true);
const page = ref(1);
const quality = ref(1);
const category_id = ref('');
const total = ref(0);

const selected_country = computed(()=>store.getters.getCurrency)
const brandCategories = computed(()=> (
    store.getters.brands.find(brand => brand.id === Number(brand_id.value))
))
const  getProducts = () => {
    const filter = {
        page: page.value,
        brand_id: brand_id.value,
        // quality: quality.value,
        country_id: selected_country.value.id,
        q: 'pro',
        category_id: category_id.value
    }
    SearchBrand(filter).then(res=> {
        Products.value = res.data.Product.Products;
        more_sales.value = res.data.Product.more_sales;
        loading.value = false;
        total.value = res.data.paging.total
    }).catch(e=> {
        loading.value = false
        console.log(e)
    })
}

const handleQualityFilter = id => {
    category_id.value = id;
    console.log(brand_id.value)

    getProducts()
}

onMounted(()=>getProducts(route.params.id))

</script>

<style>
.filter_buttons {
    display: flex;
    margin-bottom: 1rem;
}

.filter_buttons button {
    min-width: 80px;
}

.filter_buttons button:not(:first-of-type) {
    margin: 0 .875rem;
}

@media (min-width: 992px) {
    .filter_buttons {
        padding: 0 2rem;
    }
}

.heading-custom {
    text-align: start;
}

</style>